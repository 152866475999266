import React from 'react';
import {Chart, Line, Point, Tooltip, Legend, Axis} from 'bizcharts';
import {defineProperty} from "../../../utils/HtmlUtils";

export default function SpiderTable(props) {
    const {list} = props;

    const formatList = list.map(a=>{
        const date = new Date()
        date.setHours(date.getHours() + a.time);
        return Object.assign({}, a, defineProperty({}, "time", date.format("M-d:h")))
    })
    formatList.reverse();

    const scale = {
        count: {
            min: 0,
            alias: '爬取量'
        },
        ip: {}
    }

    return <Chart scale={scale} autoFit height={480} data={formatList}>
        <Point position="time*count" color="ip" shape='circle' />
        <Line shape="smooth" position="time*count" color="ip" label="count" />
        <Tooltip shared showCrosshairs />
        <Axis name="count" title/>
        <Axis name="time"/>
        <Legend background={{
            padding:[5,240,5,36],
            style: {
                fill: '#eaeaea',
                stroke: '#fff'
            }
        }} />
    </Chart>
}
import React, {Component} from "react";
import {updateBotSenderTask} from "../../../api";
import {emptyFunc, isNull} from "../../../utils/HtmlUtils";
import {Switch} from "antd";

export default class UpdateSenderTaskSwitch extends Component {

    handleChange = (checked, e) => {
        const {record={}, columnKey, onSuccess=emptyFunc} = this.props;
        const {id} = record;
        updateBotSenderTask({id, taskId: columnKey, checked}).then(onSuccess);
    }

    render() {
        return (
            <Switch size="small" checked={isNull(this.props.value, false)} onChange={this.handleChange}/>
        )
    }
}
import React, {Component} from "react";
import DefaultTable from "../../../components/default-table";
import {getRedisByCondition} from "../../../api";
import UpdateRedis from "./UpdateRedis";
import DeleteRedis from "./DeleteRedis";
import {isNull} from "../../../utils/HtmlUtils";

export default class RedisManager extends Component {
	columnsConfig = [
		{title: '类型', key: 'type', width: 100, type: 'chooseRender', chooseMap: 'typeList'},
		{title: '键', key: 'key', width: 300, type: 'search'},
		{title: '子键', key: 'subKey', width: 300, type: 'search'},
		{title: '值', key: 'value', width: 800, type: 'custom', ellipsis: true},

	];

	handleButtonsInit = (that) => {
		const { state, handleUpdated } = that
		const { selectedRows } = state
		const selectedRow = selectedRows[0] || {};
		return <>
			<UpdateRedis selectedRow={selectedRow} onSuccess={handleUpdated}/>
			<DeleteRedis selectedRows={selectedRows} onSuccess={handleUpdated}/>
		</>
	}

	handleResourcesInit = () => ({
		typeList: [{value: "string", text: '字符'}, {value: "set", text: '列表'}, {value: "set", text: '去重列表'}, {value: "hash", text: "字典"}]
	})

	valueRender (text, record) {
		return JSON.stringify(text);
	}

	render() {
		return (
			<DefaultTable
				isMultipleSelect={true}
				defaultPageSize={20}
				rowKey={record => record.key + "，" + isNull(record.subKey, "")}
				columnsConfig={this.columnsConfig}
				getDataApi={getRedisByCondition}
				onButtonsInit={this.handleButtonsInit}
				onResourcesInit={this.handleResourcesInit}
			/>
		)
	}

}
import React, {Component} from "react";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";
import {addBotTask} from "../../../api";

export default class AddBotTask extends Component {
    render() {
        return (
            <DefaultModalButton
                {...this.props}
                col={2}
                title='新增BotTask'
                value='新增BotTask'
                formConfig={this.formConfig}
                updateApi={addBotTask}
            />
        )
    }

    formConfig = [
        {label: '任务名', key: 'name', type: 'input'},
        {label: '指令列表', key: 'keyListStr', type: 'input'},
        {label: '排序值', key: 'sort', type: 'inputNumber', value: 0},
        {label: '帮助', key: 'description', type: 'textArea'},
    ]
}
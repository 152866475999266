import React, {Component} from "react";
import {convertAiTag} from "../../../api";
import {Card, Input, Row, Spin, Typography} from "antd";
import {isBlank} from "../../../utils/HtmlUtils";

export default class AiImageTag extends Component {

	constructor(props) {
		super(props)

		this.state = {
			text: "",
			result: "",
			isChange: false,
			loading: false,
			intervalId: setInterval(this.refresh, 1000)
		}
	}

	refresh = () => {
		const {text, isChange, loading} = this.state;
		if (!isChange) {
			return;
		}
		if (loading) {
			return
		}
		if (isBlank(text)) {
			return
		}
		if (!(text.endsWith(",") || text.endsWith("，"))) {
			return
		}
		this.setState({loading: true})
		convertAiTag({tagListStr: text})
			.then(result => this.setState({result: result, isChange: text !== this.state.text}))
			.finally(() => this.setState({loading: false}))
	}

	handleChange = (event) => {
		this.setState({text: event.target.value, isChange: true})
	}

	render() {
		return (
			<Card tabIndex="-1" size="small">
				<Row>Tips: 输入逗号以触发翻译</Row>
				<Row>
					<Input.TextArea rows={4} value={this.state.text} onChange={this.handleChange} />
				</Row>
				<Row>
					<Spin spinning={this.state.loading}>
						<Typography.Paragraph copyable>{this.state.result}</Typography.Paragraph>
					</Spin>
				</Row>
			</Card>
		)
	}

}
import React, {Component} from "react";
import {copyBotSenderMapping} from "../../../api";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";

const formConfig = [
    {label: '从任务', key: 'fromTaskId', type: 'select', resource: 'BotTaskResource'},
    {label: '到任务', key: 'toTaskId', type: 'select', resource: 'BotTaskResource'},
]

export default class CopyTaskMapping extends Component {

    render() {
        return (
            <DefaultModalButton
                {...this.props}
                title="拷贝mapping"
                value='拷贝mapping'
                formConfig={formConfig}
                updateApi={copyBotSenderMapping}
            />
        )
    }
}
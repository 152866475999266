import React, {Component} from "react";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";
import {isEmpty, isNull} from "../../../utils/HtmlUtils";
import {deleteRedis} from "../../../api";

const formConfig = [
	{label: '键', key: 'keyList', type: 'input', hidden: true},
	// {label: '子键', key: 'subKeyList', type: 'input', hidden: true},
	// {label: '值', key: 'value', type: 'input', disabled: true, submit: false},
]

export default class DeleteRedis extends Component {
	render() {
		const selectedRow = {};
		selectedRow.keyList = JSON.stringify(this.props.selectedRows.map(redis => ({
			key: redis.key ,
			subKey: isNull(redis.subKey, "")
		})))
		return (
			<DefaultModalButton
				{...this.props}
				title={`删除值条数: ${this.props.selectedRows.length}`}
				value='删除值'
				formConfig={formConfig}
				selectedRow={selectedRow}
				disabled={isEmpty(this.props.selectedRows)}
				updateApi={deleteRedis}
			/>
		)
	}
}
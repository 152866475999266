import React, {Component} from "react";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";
import {isEmptyObject} from "../../../utils/HtmlUtils";
import {updateRedis} from "../../../api";

const formConfig = [
	{key: 'key', hidden: true},
	{key: 'clazz', hidden: true},
	{key: 'subKey', hidden: true},
	{label: '值', key: 'value', type: 'input'},
]

export default class UpdateRedis extends Component {
	render() {
		return (
			<DefaultModalButton
				{...this.props}
				title={`编辑值: ${this.props.selectedRow.key}`}
				value='编辑值'
				formConfig={formConfig}
				disabled={isEmptyObject(this.props.selectedRow)}
				updateApi={updateRedis}
			/>
		)
	}
}
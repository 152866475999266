import React, {Component} from "react";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";
import {batchUpdateRecommendTalk} from "../../../api";

export default class BatchUpdateRecommendTalk extends Component {
    render() {
        const {resources, buttonData} = this.props;
        const {recommendIssueResource=[]} = resources;
        const name = recommendIssueResource[0]?.text;
        const selectedRow = {op: buttonData.op,ed: buttonData.ed}
        return (
            <DefaultModalButton
                {...this.props}
                col={3}
                title={`当前期： ${name}`}
                value='批量编辑最新期对话'
                formConfig={this.formConfig}
                selectedRow={selectedRow}
                updateApi={batchUpdateRecommendTalk}
            />
        )
    }

    formConfig = [
        {label: "Tips", key: 'tips', type: 'text', value: "批量编辑后，第几句就继承第几句的语音，中间插一句就会错位，请检查语音，或者先文本再语音", col: 3},
        {label: 'OP对话', key: 'op', type: 'textArea', col: 3, autoSize: {minRows: 5}},
        {label: 'ED对话', key: 'ed', type: 'textArea', col: 3, autoSize: {minRows: 5}},
    ];
}
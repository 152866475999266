import React, {useEffect, useState} from "react";
import {getSpiderCount} from "../../../api";
import {If, ignoreError, isNotEmpty} from "../../../utils/HtmlUtils";
import {Card} from "antd";
import SpiderTable from "./SpiderTable";

export default function SpiderCount() {
    const [filter] = useState({status: 2, time: 1, reasonList: [2, 4]});
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([]);
        getSpiderCount(filter).then(setData).catch(ignoreError);
    }, [filter])

    return <Card>
        {If(isNotEmpty(data)).then(() => (
            <SpiderTable list={data || []}/>
        )).endIf()}
    </Card>
}
import React, {Component} from "react";
import DefaultTable from "../../../components/default-table";
import {getBotSenderTaskByCondition} from "../../../api";
import {emptyFunc} from "../../../utils/HtmlUtils";
import UpdateSenderTaskSwitch from "./UpdateRenderTask";
import CopyTaskMapping from "./CopyTaskMapping";
import AddBotTask from "./AddBotTask";
import AddMappingToGuild from "./AddMappingToGuild";

export default class BotSenderTaskManager extends Component {
    columnsConfig = [
        {title: "id", key: "id", width: 60},
        {title: '发送名', key: 'name', width: 150, type: 'search'},
        {title: '类型', key: 'sendType', width: 70, type: 'choose', chooseMap: 'sendTypeResource'},
    ];

    handleButtonColumnInit = (that, value, record, columnConfig) => {
        const { key } = columnConfig;
        const { handleUpdated } = that
        return (
            <>
                <UpdateSenderTaskSwitch columnKey={key} value={value} record={record} onSuccess={handleUpdated}/>
            </>
        )

    }

    handleResourceLoaded = (resources, callback) => {
        const {BotTaskResource=[]} = resources;
        BotTaskResource.map(r => this.columnsConfig.push({title: r.text, key: r.value+"", width: 90, type: 'button'}));
        (callback || emptyFunc)();
    }

    handleButtonsInit = (that) => {
        const { state, handleUpdated } = that
        const { selectedRows, resources } = state
        const selectedRow = selectedRows[0] || {}
        return (
            <>
                <CopyTaskMapping selectedRow={selectedRow} onSuccess={handleUpdated} resources={resources}/>
                <AddBotTask/>
                <AddMappingToGuild onSuccess={handleUpdated}/>
            </>
        )
    }
    handleResourcesInit = () => ({
        // sendTypeList: [{value: 'FriendMessage', text: '好友'}, {value: 'GroupMessage', text: '群聊'}, {value: 'TempMessage', text: '临时'}, {value: 'GuildMessage', text: '频道'}],
    })

    render() {
        return (
            <DefaultTable
                needResourcesList={['BotTaskResource', 'sendTypeResource']}
                defaultPageSize={50}
                defaultSorter={{field: 'id', order: 'ascend'}}
                defaultFilters={{}}
                rowKey={record => record.id}
                columnsConfig={this.columnsConfig}
                getDataApi={getBotSenderTaskByCondition}
                onButtonsInit={this.handleButtonsInit}
                onResourcesInit={this.handleResourcesInit}
                onResourceLoaded={this.handleResourceLoaded}
                onButtonColumnInit={this.handleButtonColumnInit}
            />
        )
    }

}
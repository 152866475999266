import React, {Component} from "react";
import DefaultModalButton from "../../../components/default-modal-button/DefaultModalButton";
import {addMappingToGuild} from "../../../api";

export default class AddMappingToGuild extends Component {
    render() {
        return (
            <DefaultModalButton
                {...this.props}
                col={2}
                title='给频道添加权限'
                value='给频道添加权限'
                formConfig={this.formConfig}
                updateApi={addMappingToGuild}
            />
        )
    }

    formConfig = [
        {label: '任务昵称', key: 'nick', type: 'input'},
        {label: '频道id', key: 'guildId', type: 'input'}
    ]
}